import { useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Card from "@mui/material/Card";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import queryString from "query-string";

// add-admin page components
import BaseLayout from "layouts/add-admin/components/BaseLayout";
import "config";

// Images
import loader from "assets/images/pdf/loader-numo.gif";

import { Grid } from "@mui/material";
import numberData from "assets/datafile/numberData.json";
import precautionsData from "assets/datafile/PRECAUTIONS.json";
import yantraRemediesData from "assets/datafile/yantraRemedies.json";
import trichakraRemediesData from "assets/datafile/trichakraRemedies.json";
import yantraDataLoad from "assets/datafile/Yantra.json";
import careerData from "assets/datafile/CAREER.json";
import diseaseData from "assets/datafile/DISEASE.json";
import qualitiesData from "assets/datafile/QUALITIES.json";
import characteristicsData from "assets/datafile/characteristics.json";
import "../../styles/style.css";
import ArgonButton from "components/ArgonButton";
import htmlDocx from "html-docx-js/dist/html-docx";

function ReportGenerator() {
  const reportTemplateRef = useRef(null);
  const [whichFlag, setwhichFlag] = useState("");
  const [customerFirstName, setCustomerFirstName] = useState("");
  const [customerMiddleName, setCustomerMiddleName] = useState("");
  const [customerLastName, setCustomerLastName] = useState("");
  const [givenName, setGivenName] = useState("");
  const [givenMiddleName, setGivenMiddleName] = useState("");
  const [givenLastName, setGivenLastName] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [birthMonth, setBirthMonth] = useState("");
  const [birthYear, setBirthYear] = useState("");
  const [birthNumber, setBirthNumber] = useState("");
  const [birthDestinyNumber, setBirthDestinyNumber] = useState("");
  const [bmyTotal, setBmyTotal] = useState("");
  const [customerAge, setCustomerAge] = useState("");
  const [zodiacSign, setZodiacSign] = useState("");
  const [rulingPlanet, setRulingPlanet] = useState("");
  const [jsonData, setjsonData] = useState(numberData);
  const [gemstoneData, setGemstoneData] = useState("");
  const [yantraData, setYantraData] = useState("");
  const [crystalData, setCrystalData] = useState("");
  const [remediesData, setRemediesData] = useState("");

  const [numerologyOfFirstName, setNumerologyOfFirstName] = useState("");
  const [numerologyOfLastName, setNumerologyOfLastName] = useState("");
  const [numerologyOfFirstLastName, setNumerologyOfFirstLastName] = useState("");
  const [numerologyOfGivenName, setNumerologyOfGivenName] = useState("");
  const [numerologyOfGivenMiddleName, setNumerologyOfGivenMiddleName] = useState("");
  const [numerologyOfGivenLastName, setNumerologyOfGivenLastName] = useState("");
  const [numerologyOfCompleteGivenName, setNumerologyOfCompleteGivenName] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const { cid } = queryString.parse(location.search);
  const { id } = queryString.parse(location.search);
  const { type } = queryString.parse(location.search);

  const handleExportPDFNew = async () => {
    setIsLoading(true);
    const content = document.querySelector("#element-to-print");

    const pageWidth = 20.5; // in cm
    const pageHeight = 30.5; // in cm

    const doc = new jsPDF("portrait", "pt", "a4");

    // Get all the pages to export
    const pages = document.querySelectorAll(".page");

    // Loop through each page and export it to PDF
    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];

      // Calculate the scroll position of the page
      const scrollPos = i > 0 ? page.offsetTop - pages[0].offsetTop : 0;

      // Export the page to canvas
      const canvas = await html2canvas(page, {
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight,
        scrollY: scrollPos,
        useCORS: true,
        allowTaint: true,
        scale: 3.6, // 0.8 for a4 , 1.15 for a3
        quality: 1,
        logging: true,
        dpi: 300,
        padding: [50, 50, 50, 50],
      });

      // Add the canvas to the PDF
      if (i > 0) {
        doc.addPage();
      }
      doc.addImage(
        canvas.toDataURL("image/png"),
        "PNG",
        20,
        20,
        canvas.width / 6,
        canvas.height / 6,
        "",
        "FAST"
      );
    }

    const currentDate = new Date();
    var pdfName =
      customerFirstName +
      "_" +
      customerMiddleName +
      "_" +
      customerLastName +
      "_" +
      currentDate.toISOString().slice(0, 10);
    // Save the PDF
    doc.save(pdfName, { quality: 2 });

    setIsLoading(false);
  };

  const handleExportDOCX = () => {
    const combinedHtml = document.querySelector("#element-to-print").innerHTML;
    const styledContent = `
      <style>
        .frame {
          /*border: 5px double #344767;*/
         /* padding: 0.5cm;*/
          padding: 0cm;
          margin:0cm;
          font-family: 'Poppins';
          height: 297mm; /* A4 height */
          width: 210mm;  /* A4 width */
          box-sizing: border-box;
          font-size:11pt;
          color:"#002060"
        }
        .page-break {
          page-break-before: always;
        }
        .optionnamefordoc{
          font-size: 14pt;
          font-weight: bold;
          text-align: justify;
          text-transform: uppercase;
          text-decoration: underline;
        }
        .nameheadingdoc{
          font-size:18pt !important;
        }
        .changenamedoc{
          font-size:18pt;
        }
        img {
          max-width: 100pt; /* Ensure images don't exceed their container width */
          height: 100pt; /* Maintain aspect ratio */
          /* Add any other CSS styles you want for images */
        }
        .docspacing{
          margin-top:100pt;
        }
        .imagdocs{
          height:30pt !important;
        }
      </style>
    ${combinedHtml}`;

    const blob = htmlDocx.asBlob(styledContent);
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    const currentDate = new Date();
    var docName =
      customerFirstName +
      "_" +
      customerMiddleName +
      "_" +
      customerLastName +
      "_" +
      currentDate.toISOString().slice(0, 10);
    link.download = docName;
    link.click();
  };

  if (cid) {
    useEffect(() => {
      axios
        .get(global.config.DATA.domain + "/customer/find/" + cid)
        .then((response) => {
          if (response.data["status"] == true) {
            setwhichFlag(response.data.data["flag"]);
            setCustomerFirstName(response.data.data["firstName"]);
            setCustomerMiddleName(response.data.data["middleName"]);
            setCustomerLastName(response.data.data["lastName"]);
            const date = new Date(response.data.data["dateOfBirth"]);
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            setBirthDay(day);
            setBirthMonth(month);
            setBirthYear(year);
            let birthNo = day;
            while (birthNo > 9) {
              birthNo = Math.floor(birthNo / 10) + (birthNo % 10);
            }
            setBirthNumber(birthNo);
            calculateDestinyNumber(day, month, year);
            calculateAge(day, month, year);
            calculateZodiacSignAndPlanet(day, month);
            // setBirthDestinyNumber(sum);
            setNumerologyOfFirstName(calculateNumerologyNumber(response.data.data["firstName"]));
            setNumerologyOfLastName(calculateNumerologyNumber(response.data.data["lastName"]));
            setNumerologyOfFirstLastName(
              calculateNumerologyNumber(
                response.data.data["firstName"] + response.data.data["lastName"]
              )
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, []);
  }

  if (id) {
    useEffect(() => {
      axios
        .get(global.config.DATA.domain + "/consultation/edit/" + id)
        .then((response) => {
          if (response.data["status"] == true) {
            setGivenName(response.data.data["givenName"]);
            setGivenMiddleName(response.data.data["givenMiddleName"]);
            setGivenLastName(response.data.data["givenLastName"]);
            setNumerologyOfGivenName(calculateNumerologyNumber(response.data.data["givenName"]));
            setNumerologyOfGivenMiddleName(
              calculateNumerologyNumber(response.data.data["givenMiddleName"])
            );
            setNumerologyOfGivenLastName(
              calculateNumerologyNumber(response.data.data["givenLastName"])
            );
            setNumerologyOfCompleteGivenName(
              calculateNumerologyNumber(
                response.data.data["givenName"] +
                response.data.data["givenMiddleName"] +
                response.data.data["givenLastName"]
              )
            );
            setRemediesData(response.data.data["remedies"]);
            setGemstoneData(response.data.data["gemstones"]);
            setYantraData(response.data.data["yantras"]);
            setCrystalData(response.data.data["crystals"]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, []);
  }

  useEffect(() => {
    // setNumerologyOfGivenLastName(calculateNumerologyNumber(givenName + customerLastName));
  });

  const calculateNumerologyNumber = (name) => {
    const mapping = {
      A: 1,
      B: 2,
      C: 3,
      D: 4,
      E: 5,
      F: 8,
      G: 3,
      H: 5,
      I: 1,
      J: 1,
      K: 2,
      L: 3,
      M: 4,
      N: 5,
      O: 7,
      P: 8,
      Q: 1,
      R: 2,
      S: 3,
      T: 4,
      U: 6,
      V: 6,
      W: 6,
      X: 5,
      Y: 1,
      Z: 7,
    };

    let sum = 0;
    for (let i = 0; i < name.length; i++) {
      const char = name[i].toUpperCase();
      if (mapping[char]) {
        sum += mapping[char];
      }
    }

    // while (sum > 9) {
    //   sum = Math.floor(sum / 10) + (sum % 10);
    // }
    return sum;
    // setNumerologyNumber(sum);
  };

  function calculateDestinyNumber(day, month, year) {
    // Convert day, month, and year to numbers
    const birthDay = Number(day);
    const birthMonth = Number(month);
    const birthYear = Number(year);

    // Calculate the sum of the digits in the day, month, and year
    let sum = 0;
    sum += Math.floor(birthDay / 10) + (birthDay % 10);
    sum += Math.floor(birthMonth / 10) + (birthMonth % 10);
    sum += Math.floor(birthYear / 1000);
    sum += Math.floor((birthYear % 1000) / 100);
    sum += Math.floor((birthYear % 100) / 10);
    sum += birthYear % 10;

    setBmyTotal(sum);
    // Keep adding the digits of the sum until we get a single digit
    while (sum > 9) {
      let newSum = 0;
      while (sum > 0) {
        newSum += sum % 10;
        sum = Math.floor(sum / 10);
      }
      sum = newSum;
    }

    // Set the calculated Destiny Number
    setBirthDestinyNumber(sum);
  }

  function calculateAge(day, month, year) {
    const birthDate = new Date(year, month - 1, day);
    const today = new Date();

    let ageYears = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      ageYears--;
      // Adjust the month difference if the birth date hasn't occurred yet in the current year
      const adjustedMonthDiff = monthDiff + 12;
      const ageMonths = adjustedMonthDiff % 12;
      setCustomerAge(`${ageYears} years ${ageMonths} months`);
    } else {
      const ageMonths = monthDiff % 12;
      setCustomerAge(`${ageYears} years ${ageMonths} months`);
    }
  }

  function calculateZodiacSignAndPlanet(day, month) {
    // Define an array of objects containing the start and end dates of each Zodiac sign,
    // along with its ruling planet and Destiny Number.
    const zodiacSigns = [
      {
        name: "Aquarius",
        start: { month: 1, day: 21 },
        end: { month: 2, day: 20 },
        rulingPlanet: "Saturn",
        destinyNumber: 8,
      },
      {
        name: "Pisces",
        start: { month: 2, day: 21 },
        end: { month: 3, day: 20 },
        rulingPlanet: "Jupiter",
        destinyNumber: 3,
      },
      {
        name: "Aries",
        start: { month: 3, day: 21 },
        end: { month: 4, day: 20 },
        rulingPlanet: "Mars",
        destinyNumber: 9,
      },
      {
        name: "Taurus",
        start: { month: 4, day: 21 },
        end: { month: 5, day: 20 },
        rulingPlanet: "Venus",
        destinyNumber: 6,
      },
      {
        name: "Gemini",
        start: { month: 5, day: 21 },
        end: { month: 6, day: 20 },
        rulingPlanet: "Mercury",
        destinyNumber: 5,
      },
      {
        name: "Cancer",
        start: { month: 6, day: 21 },
        end: { month: 7, day: 20 },
        rulingPlanet: "Moon",
        destinyNumber: 2,
      },
      {
        name: "Leo",
        start: { month: 7, day: 21 },
        end: { month: 8, day: 20 },
        rulingPlanet: "Sun",
        destinyNumber: 1,
      },
      {
        name: "Virgo",
        start: { month: 8, day: 21 },
        end: { month: 9, day: 20 },
        rulingPlanet: "Mercury",
        destinyNumber: 5,
      },
      {
        name: "Libra",
        start: { month: 9, day: 21 },
        end: { month: 10, day: 20 },
        rulingPlanet: "Venus",
        destinyNumber: 6,
      },
      {
        name: "Scorpio",
        start: { month: 10, day: 21 },
        end: { month: 11, day: 20 },
        rulingPlanet: "Mars",
        destinyNumber: 9,
      },
      {
        name: "Sagittarius",
        start: { month: 11, day: 21 },
        end: { month: 12, day: 20 },
        rulingPlanet: "Jupiter",
        destinyNumber: 3,
      },
      {
        name: "Capricorn",
        start: { month: 12, day: 21 },
        end: { month: 1, day: 20 },
        rulingPlanet: "Saturn",
        destinyNumber: 8,
      },
    ];

    // Loop through the array of Zodiac signs and find the one that matches the input day and month.
    for (let i = 0; i < zodiacSigns.length; i++) {
      const sign = zodiacSigns[i];
      const start = sign.start;
      const end = sign.end;

      if (
        (month === start.month && day >= start.day) ||
        (month === end.month && day <= end.day) ||
        (month > start.month && month < end.month)
      ) {
        // Return the name of the Zodiac sign and its ruling planet.
        setZodiacSign(sign.name);
        setRulingPlanet(sign.rulingPlanet + "-" + sign.destinyNumber);
      }
    }
  }

  return (
    <BaseLayout stickyNavbar>
      <ArgonBox mt={4}>
        <ArgonBox mb={3}>
          <Card sx={{ height: "100%" }}>
            <ArgonBox display="flex" justifyContent="start" alignItems="center" pt={3} px={2}>
              <ArgonTypography
                variant="h6"
                fontWeight="bold"
                textTransform="capitalize"
              ></ArgonTypography>
              <div>
                <Link to={`/consultation?cid=${cid}&id=${id}&type=${type}`}>
                  <ArgonButton
                    variant="contained"
                    className="p10"
                    style={{
                      backgroundColor: "#149cf3",
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                    }}
                  >
                    Edit Numerology
                  </ArgonButton>
                </Link>
                <ArgonButton
                  onClick={handleExportPDFNew}
                  variant="contained"
                  className="p10"
                  style={{
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    marginLeft: "5px",
                  }}
                >
                  Generate PDF
                </ArgonButton>
                <ArgonButton
                  onClick={handleExportDOCX}
                  variant="contained"
                  className="p10"
                  style={{
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    marginLeft: "5px",
                  }}
                >
                  Generate DOCX
                </ArgonButton>
              </div>
            </ArgonBox>

            <Grid lg={12} sm={12} style={{ textAlign: "center" }}>
              {isLoading && (
                <div>
                  <img
                    src={loader}
                    style={{ width: "150px", height: "150px", objectFit: "contain" }}
                  />
                  <ArgonTypography
                    variant="h6"
                    fontWeight="bold"
                    textTransform="capitalize"
                    className="mb20"
                  >
                    We are preparing your report. Hold on tight!
                  </ArgonTypography>
                </div>
              )}
            </Grid>

            <div id="element-to-print" ref={reportTemplateRef} className="mostoutside">
              <div id="page1" className="frame page changeColor mt50">
                <ArgonBox style={{ textAlign: "center" }}>
                  <img
                    src={global.config.DATA.filePath + "/pdf/ganapti.jpg"}
                    style={{ width: "90px", height: "110px", objectFit: "contain" }}
                  />
                  <h2 className="numerologyheading mt30" style={{ color: "red" }}>
                    NUMEROLOGY
                  </h2>
                </ArgonBox>
                <ArgonBox className="fs18  ">
                  <p className="mb40" style={{ textAlign: "justify" }}>
                    Numerology is a Science dealing with the mystic power of numbers and vibrations
                    like sound waves, electricity and wind which are not visible. Its power can
                    never be seen and can only felt.
                  </p>

                  <p className="mb40" style={{ textAlign: "justify" }}>
                    Dr. Julian Stenton was actually the person who came up with the name Numerology.
                    He also bought recognition and awareness of it in modern-day times.
                  </p>
                  {/* <p className="mb40" style={{ textAlign: "justify" }}>
                    Numerology, an ancient secret was passed to man from generations by God’s force.
                    Numerology, also known as the mother of occult sciences was originated in India
                    and according to the Vedas it is almost 5000 years longevously.
                  </p> */}
                  <p className="mb40" style={{ textAlign: "justify" }}>
                    While astrology is the study of the planets, their movements, and their
                    influences on individual human lives, numerology, on the other hand, deals with
                    numbers and their influences on human lives. Astrology is based on Chandrama so
                    your birth date, time of birth and place is required, but Numerology is based on
                    Surya so only name and date of birth is required. You cannot change your date of
                    birth but you can change your name to attract luck.
                  </p>
                  <p className="mb40" style={{ textAlign: "justify" }}>
                    When your name is converted into numbers, its relationship with your date of
                    birth determines your talents, your potential, your environment and your
                    relationships. Numerology indicates the signposts of your life. The
                    classification of the numbers is considered positive or negative since the
                    numbers have good and bad aspects respectively.
                  </p>
                  <p className="mb40" style={{ textAlign: "justify" }}>
                    Understanding numerology will help you gain profound insights into your
                    personality and character, your role in life, and what it takes to help yourself
                    grow.
                  </p>
                  <p className="mt30" style={{ textAlign: "justify", fontWeight: "bold" }}>
                    With Best wishes and good luck for your bright future.
                  </p>
                  {/* <div style={{textAlign: "justify" }}>
                    <ul>
                      <li>
                        Evaluating an individual’s characteristics.
                      </li>
                      <li>
                        Selecting compatible partners, companions in business and personal life.
                      </li>
                      <li>
                        A management tool for selection and placement of personnel
                      </li>
                      <li>Choosing business and product names with positive impact.</li>
                    </ul>
                  </div> */}
                  <div className="pt90" style={{ textAlign: "right" }}>
                    <h2
                      className="fs35 nameheadingdoc"
                      style={{
                        fontWeight: "500",
                        color: "#ff813a",
                      }}
                    >
                      Prashantt Kulkarnni
                    </h2>
                    <p className="mb10 fs18 nameheadingdoc">
                      Principal Consultant Astro-Numerologist-Vastu & Signalyst
                    </p>
                    <p className="mb20">+91 9769962646</p>
                  </div>
                </ArgonBox>
              </div>
              <div className="page-break">&nbsp;</div>
              <div id="page2" className="frame page changeColor mtop">
                <ArgonBox
                  className="fs18  "
                  style={{
                    textAlign: "center",
                  }}
                >
                  <span className="fs20" style={{ fontWeight: "bold" }}>
                    Our name plays an important role in our success and growth. It gives a greater
                    impact on how other people think about you
                  </span>
                  <h1
                    className="mb20 fs52 changenamedoc"
                    style={{
                      fontWeight: "500",
                      color: "#344767",
                    }}
                  >
                    {'"' + customerFirstName + '"'}
                  </h1>
                </ArgonBox>
                <p className="fs16" style={{ textAlign: "center", fontWeight: "bold" }}>
                  May You Be Blessed with Great Health, Progress, Positivity, Success and Prosperity
                </p>
                <p
                  className="mt10 fs16"
                  style={{
                    textDecoration: "underline",
                    color: "#344767",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  GOD BLESS YOU !!
                </p>

                <img
                  src={global.config.DATA.filePath + "/pdf/name.png"}
                  className="mt40 imagdocs"
                  style={{
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
                {/* <p style={{ fontWeight: 400, textAlign: "justify" }}>
                  (Each alphabet indicates a number, which can make or break us. It can be, altered
                  for positive vibrations. Name is our identity. The right calculations enhance
                  prosperity.)
                </p> */}
                <h3 className="pdf-title mb10 mt30 optionname optionnamefordoc">
                  NAME NUMBER : {customerFirstName} {"(" + numerologyOfFirstName + ")"}
                </h3>

                {jsonData &&
                  jsonData
                    .filter((item) => item.title == numerologyOfFirstName)
                    .map((item) => (
                      <div key={item.title}>
                        <p className="fs18  " style={{ textAlign: "justify" }}>
                          {item.description}
                        </p>
                      </div>
                    ))}
                <h3 className="pdf-title mb10 mt30 optionname optionnamefordoc">
                  SURNAME NUMBER : {customerLastName} {"(" + numerologyOfLastName + ")"}
                </h3>
                {jsonData &&
                  jsonData
                    .filter((item) => item.title == numerologyOfLastName)
                    .map((item) => (
                      <div key={item.title}>
                        <p className="fs18  " style={{ textAlign: "justify" }}>
                          {item.description}
                        </p>
                      </div>
                    ))}
                <h3 className="pdf-title mb10 mt30 optionname optionnamefordoc">
                  COMBINED NAME AT PRESENT :{" "}
                  {customerFirstName +
                    " " +
                    customerLastName +
                    " (" +
                    numerologyOfFirstName +
                    "+" +
                    numerologyOfLastName +
                    "=" +
                    numerologyOfFirstLastName +
                    ")"}
                </h3>

                {jsonData &&
                  jsonData
                    .filter((item) => item.title == numerologyOfFirstLastName)
                    .map((item) => (
                      <div key={item.title}>
                        <p className="fs18  " style={{ textAlign: "justify" }}>
                          {item.description}
                        </p>
                      </div>
                    ))}
                {/* Advice */}
                {whichFlag == "Baby" ? null : (
                  <>
                    <img
                      src={global.config.DATA.filePath + "/pdf/advice.png"}
                      className="mt40"
                      style={{
                        width: "100%",
                        height: 47,
                        objectFit: "contain",
                      }}
                    />
                    {givenName ? (
                      <div className="mt10" style={{ textAlign: "center" }}>
                        <p className="fs18  " style={{ fontWeight: "bold" }}>
                          YOU NEED SPELLING CORRECTIONS IN NAME NUMBER.{" "}
                        </p>
                      </div>
                    ) : (
                      <div className="mt10" style={{ textAlign: "center" }}>
                        <p className="fs18  " style={{ fontWeight: "bold" }}>
                          THEIR IS NO SPELLING CORRECTION.{" "}
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div>&nbsp;</div>
              {/* Options */}
              {givenName || givenLastName || givenMiddleName ? (
                <>
                  <div id="page3" className="frame page changeColor mtop">
                    <img
                      src={global.config.DATA.filePath + "/pdf/option.png"}
                      className="mt40"
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                    {givenName && givenLastName && givenMiddleName ? (
                      <ArgonBox
                        className="fs15"
                        style={{
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        <h1
                          className="mb20 fs48 changenamedoc"
                          style={{
                            fontWeight: "500",
                            color: "#344767",
                          }}
                        >
                          {'"' + givenName + " " + givenMiddleName + " " + givenLastName + '"'}
                        </h1>
                      </ArgonBox>
                    ) : givenName && givenLastName ? (
                      <ArgonBox
                        className="fs18  "
                        style={{
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        <h1
                          className="mb20 fs48 changenamedoc"
                          style={{
                            fontWeight: "500",
                            color: "#344767",
                          }}
                        >
                          {'"' + givenName + " " + givenLastName + '"'}
                        </h1>
                      </ArgonBox>
                    ) : givenName ? (
                      <ArgonBox
                        className="fs15"
                        style={{
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        <h1
                          className="mb20 fs48 changenamedoc"
                          style={{
                            fontWeight: "500",
                            color: "#344767",
                          }}
                        >
                          {'"' + givenName + '"'}
                        </h1>
                      </ArgonBox>
                    ) : givenLastName ? (
                      <ArgonBox
                        className="fs15"
                        style={{
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        <h1
                          className="mb20 changenamedoc"
                          style={{
                            fontWeight: "500",
                            color: "#344767",
                          }}
                        >
                          {'"' + givenLastName + '"'}
                        </h1>
                      </ArgonBox>
                    ) : null}

                    {givenName && (
                      <>
                        <h4 className="pdf-title mb10 mt30 optionname optionnamefordoc">
                          OPTION FOR NAME NUMBER : {givenName + " (" + numerologyOfGivenName + ")"}
                        </h4>
                        {jsonData &&
                          jsonData
                            .filter((item) => item.title == numerologyOfGivenName)
                            .map((item) => (
                              <div key={item.title}>
                                <p className="fs18  " style={{ textAlign: "justify" }}>
                                  {item.description}
                                </p>
                              </div>
                            ))}
                      </>
                    )}
                    {givenLastName && (
                      <>
                        <h3 className="pdf-title mt30 mb10 optionname optionnamefordoc">
                          OPTION FOR SURNAME NUMBER :{" "}
                          {givenLastName + " (" + numerologyOfGivenLastName + ")"}
                        </h3>
                        {jsonData &&
                          jsonData
                            .filter((item) => item.title == numerologyOfGivenLastName)
                            .map((item) => (
                              <div key={item.title}>
                                <p className="fs18  " style={{ textAlign: "justify" }}>
                                  {item.description}
                                </p>
                              </div>
                            ))}
                      </>
                    )}
                    {givenName && givenLastName && (
                      <>
                        <h3 className="pdf-title mt30 mb10 optionname optionnamefordoc">
                          OPTION FOR GIVEN NUMBER :{" "}
                          {givenName +
                            " " +
                            givenMiddleName +
                            " " +
                            givenLastName +
                            " (" +
                            numerologyOfGivenName +
                            "+" +
                            numerologyOfGivenMiddleName +
                            "+" +
                            numerologyOfGivenLastName +
                            "=" +
                            numerologyOfCompleteGivenName +
                            ")"}
                        </h3>
                        {jsonData &&
                          jsonData
                            .filter((item) => item.title == numerologyOfCompleteGivenName)
                            .map((item) => (
                              <div key={item.title}>
                                <p className="fs18  " style={{ textAlign: "justify" }}>
                                  {item.description}
                                </p>
                              </div>
                            ))}
                      </>
                    )}
                    <p className="mt30" style={{ fontWeight: 900, textAlign: "justify" }}>
                      PRACTICE NEW SPELT NAME & SURNAME 108 TIMES FOR 7 DAYS ON YOUR AUSPICIOUS DAYS
                      & DATES. START WITH A PRAYER.
                    </p>
                  </div>
                  <div className="page-break">&nbsp;</div>
                </>
              ) : (
                <span></span>
              )}

              <div id="page4" className="frame page changeColor mtop">
                {yantraDataLoad.length > 0 && (
                  <ArgonBox>
                    <div>
                      {yantraRemediesData &&
                        yantraRemediesData
                          .filter((item) => item.title == birthNumber)
                          .map((item) => (
                            <div className="fs18  " key={item.title}>
                              {/* <p style={{color: "#344767", fontWeight: 900 }}>
                                {item.name}
                              </p> */}
                              <ArgonBox style={{ textAlign: "center" }}>
                                <img
                                  src={global.config.DATA.filePath + "/numberr/" + item.image}
                                  className="mb30"
                                  style={{
                                    width: "160px",
                                    height: "160px",
                                    objectFit: "contain",
                                    border: "3px solid #344767",
                                  }}
                                />
                              </ArgonBox>
                            </div>
                          ))}
                    </div>
                  </ArgonBox>
                )}
                <table className="fs17" style={{ color: "#344767" }}>
                  <thead style={{ borderBottom: "1px solid black" }}>
                    {/* <tr>
                      <th style={{ width: "300pt" }}>Field</th>
                      <th style={{ width: "300pt" }}>Value</th>
                    </tr> */}
                    <tr>
                      <th style={{ width: "300pt", borderBottom: "1px solid black" }}>Name</th>
                      <th style={{ width: "300pt", borderBottom: "1px solid black" }}>
                        {customerFirstName} {customerLastName}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ borderBottom: "1px solid black" }}>Birth Date</td>
                      <td style={{ borderBottom: "1px solid black" }}>
                        {birthDay} / {birthMonth} / {birthYear}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ borderBottom: "1px solid black" }}>Birth Number</td>
                      <td style={{ borderBottom: "1px solid black" }}>{birthNumber}</td>
                    </tr>
                    <tr>
                      <td style={{ borderBottom: "1px solid black" }}>Destiny Number</td>
                      <td style={{ borderBottom: "1px solid black" }}>{birthDestinyNumber}</td>
                    </tr>
                    <tr>
                      <td style={{ borderBottom: "1px solid black" }}>Zodiac Sign</td>
                      <td style={{ borderBottom: "1px solid black" }}>
                        {zodiacSign} {rulingPlanet}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ borderBottom: "1px solid black" }}>BMY Total</td>
                      <td style={{ borderBottom: "1px solid black" }}>{bmyTotal}</td>
                    </tr>
                    <tr>
                      <td style={{ borderBottom: "1px solid black" }}>Age</td>
                      <td style={{ borderBottom: "1px solid black" }}>{customerAge}</td>
                    </tr>
                  </tbody>
                </table>

                {/* <Grid container className="p10 fs17" style={{ justifyContent: "center" }} lg={10}>
                  <Grid container className="p10 fs17" style={{ backgroundColor: "#ffede3" }}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      Name
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      {customerFirstName + " " + customerLastName}
                    </Grid>
                  </Grid>
                  <Grid container className="p10 fs17">
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      Birth Date
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      {birthDay} / {birthMonth} / {birthYear}
                    </Grid>
                  </Grid>
                  <Grid container className="p10 fs18" style={{ backgroundColor: "#ffede3" }}>
                    <Grid item xs={12} sm={4} md={3} lg={6}>
                      Birth Number
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={6}>
                      {birthNumber}
                    </Grid>
                  </Grid>
                  <Grid container className="p10 fs17">
                    <Grid item xs={12} sm={4} md={3} lg={6}>
                      Destiny Number
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={6}>
                      {birthDestinyNumber}
                    </Grid>
                  </Grid>
                  <Grid container className="p10 fs18" style={{ backgroundColor: "#ffede3" }}>
                    <Grid item xs={12} sm={4} md={3} lg={6}>
                      Zodiac Sign
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={6}>
                      {zodiacSign + " " + rulingPlanet}
                    </Grid>
                  </Grid>
                  <Grid container className="p10 fs17">
                    <Grid item xs={12} sm={4} md={3} lg={6}>
                      BMY Total
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={6}>
                      {bmyTotal}
                    </Grid>
                  </Grid>
                  <Grid container className="p10 fs17" style={{ backgroundColor: "#ffede3" }}>
                    <Grid item xs={12} sm={4} md={3} lg={6}>
                      AGE
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={6}>
                      {customerAge}
                    </Grid>
                  </Grid>
                </Grid> */}
                {/* Characteristics */}
                <p
                  className="pdf-title mt30 mb10 fs19"
                  style={{
                    color: "#344767",
                    fontWeight: "bold",
                    textAlign: "justify",
                    textTransform: "uppercase",
                  }}
                >
                  CHARACTERISTICS OF NUMBER : {birthNumber}
                </p>
                {characteristicsData &&
                  characteristicsData
                    .filter((item) => item.title == birthNumber)
                    .map((item) => (
                      <div className="fs18  " key={item.title}>
                        <p style={{ textAlign: "justify" }}>{item.mainCharacteristics}</p>
                        <p
                          className="pdf-title mt30 mb10 fs19"
                          style={{
                            color: "#344767",
                            fontWeight: "bold",
                            textAlign: "justify",
                            textTransform: "uppercase",
                          }}
                        >
                          IMPORTANT
                        </p>
                        <li className="mb10">{"Lucky Day :- " + item.luckyDay}</li>
                        <li className="mb10">{"Lucky Dates :- " + item.luckyDates}</li>
                        <li className="mb10">{"Lucky Colors :- " + item.luckyColor}</li>
                        <li className="mb10">{"Unlucky Dates :- " + item.unluckyDates}</li>
                        <li className="mb10">{"Unlucky Colors :- " + item.unluckyColor}</li>
                        <li className="mb10">{"Mobile Number :- " + item.luckyMobileNo}</li>
                        <li className="mb10">{"Account Number :- 33, 42, 60"}</li>
                      </div>
                    ))}
              </div>
              <div className="page-break">&nbsp;</div>
              {/* Your basic characteristics */}
              {/* <div
                id="page5"
                className="page"
                style={{color: "#002060", border: "10px double #344767"  }}
              >
                <img
                  src={basicCharacteristics}
                  style={{
                    width: "100%",
                    objectFit: "contain"
                  }}
                />
              </div> */}
              {/* Positive and Negative Qualities */}
              <div id="page6" className="frame page changeColor mtop">
                <img
                  src={global.config.DATA.filePath + "/pdf/character.png"}
                  className="mt40"
                  style={{
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
                {/* {qualitiesData &&
                  qualitiesData
                    .filter((item) => item.title == birthNumber)
                    .map((item) => (
                      <div
                        key={item.title}
                        style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                      >
                        <div>
                          {" "}
                          <p className="pdf-title">POSITIVE QUALITIES</p>
                          <ul>
                            {item.positiveQualities.split(",").map((line, index) => (
                              <li key={index}>
                                {line}
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div>
                          <p
                            style={{
                              color: "#344767",
                              fontWeight: 700,
                            }}
                          >
                            NEGATIVE QUALITIES
                          </p>
                          <ul>
                            {item.negativeQualities.split(",").map((line, index) => (
                              <li key={index}>
                                {line}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ))} */}
                {characteristicsData &&
                  characteristicsData
                    .filter((item) => item.title == birthNumber)
                    .map((item) => (
                      <div key={item.title}>
                        <p
                          className="pdf-title mt30 mb10 fs19"
                          style={{
                            color: "#344767",
                            fontWeight: "bold",
                            textAlign: "justify",
                            textTransform: "uppercase",
                          }}
                        >
                          PERSONAL TRAITS :{" "}
                        </p>
                        <p className="fs18  " style={{ textAlign: "justify" }}>
                          {item.Personaltraits}
                        </p>
                        {whichFlag == "Individual" ? (
                          <>
                            <p
                              className="pdf-title mt30 mb10 fs19"
                              style={{
                                color: "#344767",
                                fontWeight: "bold",
                                textAlign: "justify",
                                textTransform: "uppercase",
                              }}
                            >
                              WHAT THEY LACK AND NEED TO IMPROVE UPON:
                            </p>
                            <p className="fs18  " style={{ textAlign: "justify" }}>
                              {item.whatTheyLack}
                            </p>
                          </>
                        ) : null}
                      </div>
                    ))}
              </div>
              <div className="page-break">&nbsp;</div>
              {/*  Health precautions HEALTH PRECAUTION and PROFESSION */}
              <div id="page7" className="frame page changeColor mtop">
                {whichFlag == "Individual" ? (
                  <>
                    {" "}
                    <p
                      className="pdf-title mt30 mb10 fs19"
                      style={{
                        color: "#344767",
                        fontWeight: "bold",
                        textAlign: "justify",
                        textTransform: "uppercase",
                      }}
                    >
                      {" "}
                      HEALTH PRECAUTION (IN GENERAL) : NUMBER {birthNumber}
                    </p>
                    {diseaseData &&
                      diseaseData
                        .filter((item) => item.title == birthNumber)
                        .map((item) => (
                          <div key={item.title}>
                            <p className="fs18  " style={{ textAlign: "justify" }}>
                              {item.discription}
                            </p>
                          </div>
                        ))}
                  </>
                ) : null}

                <p
                  className="pdf-title mt30 mb10 fs19"
                  style={{
                    color: "#344767",
                    fontWeight: "bold",
                    textAlign: "justify",
                    textTransform: "uppercase",
                  }}
                >
                  PROFESSION (IN GENERAL) : NUMBER {birthNumber}
                </p>
                {careerData &&
                  careerData
                    .filter((item) => item.title == birthNumber)
                    .map((item) => (
                      <div key={item.title}>
                        {item.profession.split("\n").map((line, index) => (
                          <p className="fs18   mb10" style={{ textAlign: "justify" }} key={index}>
                            {line}
                          </p>
                        ))}
                        <p
                          className="pdf-title mt30 mb10 fs18 fs19"
                          style={{
                            color: "#344767",
                            fontWeight: "bold",
                            textAlign: "justify",
                            textTransform: "uppercase",
                          }}
                        >
                          {item.idealProfessionHeading}
                        </p>
                        {item.idealProfession.split("\n").map((line, index) => (
                          <p className="fs18   mb10" style={{ textAlign: "justify" }} key={index}>
                            {line}
                          </p>
                        ))}
                        <p
                          className="pdf-title mt30 mb10 fs19"
                          style={{
                            color: "#344767",
                            fontWeight: "bold",
                            textAlign: "justify",
                            textTransform: "uppercase",
                          }}
                        >
                          {item.title == 3 ? "Business" : "They are meant for"} :
                        </p>
                        <p className="fs18  " style={{ textAlign: "justify" }}>
                          {item.subjectsOfInterest}
                        </p>
                      </div>
                    ))}
              </div>
              <div className="page-break">&nbsp;</div>
              {/* Gemstone */}
              {/* {gemstoneData.length > 0 && ( */}
              {gemstoneData &&
                gemstoneData.map((item) => (
                  <>
                    <div key={item._id} id="page8" className="frame page changeColor mtop">
                      <img
                        src={global.config.DATA.filePath + "/pdf/gemstone.png"}
                        className="mt40"
                        style={{
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                      <ArgonBox className="mt50">
                        <div>
                          <div style={{ textAlign: "center" }}>
                            <img
                              src={global.config.DATA.filePath + "/" + item.type.image}
                              className="mb10"
                              style={{
                                height: "200px",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                          <p className="mb10" style={{ textDecoration: "underline" }}>
                            {item.type.name} :
                          </p>
                          <p className="fs18   mb30">{item.type.discription}</p>
                        </div>
                      </ArgonBox>
                    </div>
                    <div className="page-break">&nbsp;</div>
                  </>
                ))}

              {/* )} */}
              {/* Yantra */}
              {/* {yantraData.length > 0 && ( */}
              {yantraData &&
                yantraData.map((item) => (
                  <>
                    <div key={item._id} id="page9" className="frame page changeColor mtop">
                      <img
                        src={global.config.DATA.filePath + "/pdf/yantra.png"}
                        className="mt40"
                        style={{
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                      <ArgonBox className="mt50">
                        <div>
                          <div style={{ textAlign: "center" }}>
                            <img
                              src={global.config.DATA.filePath + "/" + item.type.image}
                              className="mb20"
                              style={{
                                width: "100px",
                                height: "200px",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                          <p className="mb10" style={{ textDecoration: "underline" }}>
                            {item.type.name} :
                          </p>
                          <p className="fs18   mb30">{item.type.discription}</p>
                        </div>
                      </ArgonBox>
                    </div>
                    <div className="page-break">&nbsp;</div>
                  </>
                ))}

              {/*  )} */}
              {/* Crystals */}
              {/* {crystalData.length > 0 && ( */}
              {crystalData &&
                crystalData.map((item) => (
                  <>
                    <div key={item._id} id="page10" className="frame page changeColor mtop">
                      <img
                        src={global.config.DATA.filePath + "/pdf/crystal.png"}
                        className="mt40"
                        style={{
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                      <ArgonBox className="mt50">
                        <div>
                          <div style={{ textAlign: "center" }}>
                            <img
                              src={global.config.DATA.filePath + "/" + item.type.image}
                              className="mb20"
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                          <p className="mb10" style={{ textDecoration: "underline" }}>
                            {item.type.name} :
                          </p>
                          <p className="fs18   mb30">{item.type.discription}</p>
                        </div>
                      </ArgonBox>
                    </div>
                    <div className="page-break">&nbsp;</div>
                  </>
                ))}

              {/* )} */}
              {/* for Precautions */}
              <div
                id="page11"
                className="frame page changeColor mtop"
                style={{
                  position: "relative",
                }}
              >
                {whichFlag == "Individual" ? (
                  <ArgonBox>
                    <img
                      src={global.config.DATA.filePath + "/pdf/precautions.png"}
                      className="mt40"
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                    <p className="mt30" style={{ color: "#344767", fontWeight: 900 }}>
                      Number : {birthNumber}
                    </p>
                    <div>
                      {precautionsData &&
                        precautionsData
                          .filter((item) => item.title == birthNumber)
                          .map((item) => (
                            <div className="fs18  " key={item.title}>
                              <ul className="mt30" style={{ marginLeft: "10px" }}>
                                {item.description.split("\n").map((line, index) => (
                                  <li key={index} className="mb10">
                                    {line}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ))}
                    </div>
                  </ArgonBox>
                ) : null}

                {/* for Yantra */}
                {yantraDataLoad.length > 0 && (
                  <ArgonBox>
                    <div>
                      <img
                        src={global.config.DATA.filePath + "/pdf/mantra.png"}
                        className="mt80"
                        style={{
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                      {yantraDataLoad &&
                        yantraDataLoad
                          .filter((item) => item.title == birthNumber)
                          .map((item) => (
                            <div className="fs18  " key={item.title}>
                              <p className="mt30" style={{ color: "#344767", fontWeight: 900 }}>
                                {item.name}
                              </p>
                              <img
                                src={global.config.DATA.filePath + "/yantra/" + item.image}
                                className="mb10 mt20"
                                style={{
                                  width: "150px",
                                  height: "100px",
                                  objectFit: "contain",
                                }}
                              />
                              <p className="fs18  ">{item.subDescription}</p>
                              <p
                                className="mb20 mt60 fs22"
                                style={{
                                  color: "#344767",
                                }}
                              >
                                <strong>MANTRA </strong>
                                <span>{" : " + item.mantra}</span>
                              </p>
                              <p className="fs18  ">{item.description}</p>
                              <div>
                                <p
                                  className="mb20 fs12"
                                  style={{
                                    position: "absolute",
                                    marginRight: 90,
                                    bottom: 0,
                                    textAlign: "center",
                                    color: "#344767",
                                  }}
                                >
                                  {/* <span style={{ fontWeight: 700 }}>Disclaimer :</span>{" "} */}
                                  <span style={{ color: "#344767" }}>
                                    Although all necessary steps have been taken for the accuracy of
                                    the complex calculations, We does not make any warranties on the
                                    accuracy or interpretations expresses or implied Success is very
                                    much the intersection of luck and hard work
                                  </span>
                                </p>
                              </div>
                            </div>
                          ))}
                    </div>
                  </ArgonBox>
                )}
              </div>
              <div className="page-break">&nbsp;</div>
              {/* for Yantra */}
              <div
                id="page12"
                className="frame page changeColor mtop"
                style={{
                  position: "relative",
                }}
              >
                <img
                  src={global.config.DATA.filePath + "/pdf/remedies.png"}
                  className="mt40"
                  style={{
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
                {trichakraRemediesData &&
                  trichakraRemediesData
                    .filter((item) => item.title == birthNumber)
                    .map((item) => (
                      <div className="fs18  " key={item.title}>
                        <p className="mt30" style={{ color: "#344767", fontWeight: 900 }}>
                          {"Trichakra Remedies " + item.name + " - " + birthNumber}
                        </p>
                        <span>
                          <div className="mt10">
                            {item.content.map((item1, index) => (
                              <>
                                <span className="mt20">
                                  <li key={index}>{item1.description}</li>
                                  <div>
                                    {item1.subDescription.map((item2, index) => (
                                      <li
                                        key={index}
                                        style={{ listStyleType: "none", marginLeft: 30 }}
                                      >
                                        {index + 1 + ". " + item2}
                                      </li>
                                    ))}
                                  </div>
                                </span>
                              </>
                            ))}
                          </div>
                        </span>
                      </div>
                    ))}
              </div>
              {remediesData.length > 0 && (
                <>
                  <div className="page-break">&nbsp;</div>
                  <div
                    id="page13"
                    className="frame page changeColor mtop"
                    style={{
                      position: "relative",
                    }}
                  >
                    <img
                      src={global.config.DATA.filePath + "/pdf/remedies.png"}
                      className="mt40"
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                    <ArgonBox>
                      <div className="mt40">
                        {remediesData.split(".").map((sentence, index) => (
                          <li key={index}>{sentence}</li>
                        ))}
                      </div>
                    </ArgonBox>
                    <ArgonBox>
                      <p
                        style={{
                          marginTop: "40%",
                          textAlign: "center",
                          color: "#344767",
                          fontWeight: 700,
                        }}
                      >
                        GOD BLESS YOU WITH SUCCESS IN ALL YOUR ENDEAVOURS !!
                      </p>

                      <div
                        className="pt70"
                        style={{
                          textAlign: "right",
                          position: "absolute",
                          bottom: "4%",
                          right: "10%"
                        }}
                      >
                        <h2
                          className="fs35 nameheadingdoc docspacing"
                          style={{
                            fontWeight: "500",
                            color: "#ff813a",
                          }}
                        >
                          Prashantt Kulkarnni
                        </h2>
                        <p className="mb20 mediumfontsize">
                          Principal Consultant Astro-Numerologist-Vastu & Signalyst
                        </p>
                        <p className="mb20">+91 9769962646</p>
                      </div>
                    </ArgonBox>
                  </div>
                </>
              )}
            </div>
          </Card>
        </ArgonBox>
      </ArgonBox>
    </BaseLayout>
  );
}

export default ReportGenerator;
